.complex {
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
  display: flex;
}

.complex-title {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.complex-title__head {
  text-align: left;
  margin: 0;
  font-size: 52px;
  font-weight: 700;
}

.complex-title__link {
  cursor: pointer;
  text-align: left;
  color: #1e1e1e80;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s;
}

.complex-title__link:hover {
  color: #1e1e1e;
}

.complex-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1440px;
  display: grid;
}

.complex-grid__item {
  flex-direction: column;
  gap: 16px;
  height: auto;
  display: flex;
  position: relative;
}

.complex-grid__item-link {
  color: #1e1e1e;
  text-decoration: none;
}

.complex-grid__img {
  border-radius: 40px;
  width: 100%;
  height: 500px;
  position: relative;
  overflow: clip;
}

.complex-grid__img-picture {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: transform .3s;
}

.complex-grid__img-picture:hover {
  transform: scale(1.3);
}

.complex-grid__text {
  flex-wrap: nowrap;
  justify-content: space-between;
  display: flex;
}

.complex-grid__text--adress, .complex-grid__text--price {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}

.complex-grid_text_bottom {
  text-align: left;
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 600;
}

.complex-grid__img-text {
  flex-wrap: nowrap;
  gap: 8px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.complex-grid__img-text-status {
  color: var(--basic-white);
  text-align: left;
  backdrop-filter: blur(2px);
  background: #00000080;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 126px;
  height: 32px;
  font-size: 13px;
  font-weight: 400;
  display: flex;
}

.complex-grid__img-text-status--red {
  height: inherit;
  color: #ba4141;
  font-size: 24px;
}

.complex__item-link:before {
  content: url("../../../images/main/icon.2365fbf5.svg");
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
  backdrop-filter: blur(2px);
  background: #00000080;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: auto;
  transition: bottom .3s;
  display: flex;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
}

.complex__item-link:hover:before {
  bottom: 25px;
}

@media (width <= 1100px) {
  .complex-grid__img {
    height: 406px;
  }

  .complex-grid__img-text__status {
    width: 100px;
  }

  .complex-grid__text {
    flex-direction: column;
    gap: 8px;
  }
}

@media (width <= 1097px) {
  .complex-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 900px) {
  .complex__item-link:before {
    display: none;
  }

  .complex-grid__img-picture:hover {
    transform: none;
  }
}

@media (width <= 601px) {
  .complex {
    gap: 32px;
  }

  .complex-grid__img-text__status {
    width: 120px;
  }

  .complex-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .complex-title__head {
    width: 70%;
    font-size: 42px;
  }

  .complex-title__link {
    font-size: 16px;
  }

  .complex {
    margin-bottom: 64px;
  }
}

@media (width <= 451px) {
  .complex-title {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
/*# sourceMappingURL=index.69313e46.css.map */
